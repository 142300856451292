import React, { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { supabase } from './lib/supabaseClient';
import Login from './components/Login';
import Game from './components/Game';
import Leaderboard from './components/Leaderboard';
import './App.css';

function App() {
  const { ready, authenticated, user } = usePrivy();

  const handleGameScore = async (event) => {
    // Only handle the string format message
    if (typeof event.data === 'string' && event.data.includes('SCORE:')) {
      const score = parseInt(event.data.split(':')[1].trim());

      // Only submit if score is a valid number
      if (!isNaN(score)) {
        try {
          const { data, error } = await supabase
            .from('leaderboard')
            .insert([
              {
                score: score,
                user_id: user?.id || 'anonymous',
                username: user?.email?.address?.split('@')[0] || 'Anonymous',
                wallet: user?.wallet?.address || null,
                email: user?.email?.address || null
              }
            ]);

          if (error) {
            console.error('Error details:', error);
            throw error;
          }
          console.log('Score submitted successfully:', data);
        } catch (error) {
          console.error('Error submitting score:', error);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleGameScore);
    return () => window.removeEventListener('message', handleGameScore);
  }, [user]);

  if (!ready) {
    return null;
  }

  if (!authenticated) {
    return <Login />;
  }

  return (
    <div className="App">
      <Game />
      <Leaderboard />
    </div>
  );
}

export default App;
