import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabaseClient';

function Leaderboard() {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    fetchScores();
    
    // Set up real-time subscription
    const subscription = supabase
      .channel('leaderboard_changes')
      .on('postgres_changes', 
        { event: 'INSERT', schema: 'public', table: 'leaderboard' },
        (payload) => {
          fetchScores();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  async function fetchScores() {
    try {
      const { data, error } = await supabase
        .from('leaderboard')
        .select('*')
        .order('score', { ascending: true })
        .limit(10);

      if (error) throw error;
      setScores(data);
    } catch (error) {
      console.error('Error fetching scores:', error);
    }
  }

  return (
    <div className="leaderboard">
      <h2>Top Scores</h2>
      <div className="scores-list">
        {scores.map((score, index) => (
          <div key={score.id} className="score-item">
            <span>{index + 1}.</span>
            <span>{score.username || 'Anonymous'}</span>
            <span>{score.score}s</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Leaderboard; 