import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://ywcaszwmqlitoptjkfln.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inl3Y2FzendtcWxpdG9wdGprZmxuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE1NTE2ODIsImV4cCI6MjA0NzEyNzY4Mn0.ZAynhzrw_Sx8je1ljvhPlu1HC4Inxaze6NlakEboFj4'

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  }
}) 