import { useEffect } from 'react';

function Game() {
  useEffect(() => {
    // Create an iframe to load the game
    const iframe = document.createElement('iframe');
    iframe.src = '/game/index.html'; // Adjust this to your game's main HTML file
    iframe.style.width = '100%';
    iframe.style.height = '100vh';
    iframe.style.border = 'none';
    
    const container = document.getElementById('game-container');
    container.appendChild(iframe);

    return () => {
      container.removeChild(iframe);
    };
  }, []);

  return <div id="game-container"></div>;
}

export default Game; 