import { usePrivy } from '@privy-io/react-auth';

function Login() {
  const { login } = usePrivy();

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to KrashWorld</h1>
        <button onClick={login} className="login-button">
          Login to Play
        </button>
      </header>
    </div>
  );
}

export default Login; 